import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Highlighter from "react-highlight-words";
import {
  Button,
  Checkbox,
  Col,
  Flex,
  Form,
  Input,
  message,
  Modal,
  Row,
  Space,
  Table,
  TablePaginationConfig,
  Tag
} from "antd";
import {ColumnsType, ColumnType} from "antd/es/table";
import {CheckboxValueType} from "antd/es/checkbox/Group";
import {FilterConfirmProps, FilterDropdownProps, FilterValue, SorterResult} from "antd/es/table/interface";
import {SearchOutlined, UserAddOutlined} from "@ant-design/icons";

import {API} from "../../config";
import {
  CHECK_RESPONSE,
  ERROR_MESSAGE,
  HEADER,
  NULL_OR_EMPTY_STRING,
  ON_TABLE_CHANGE,
  ORDER_STATE_COLOR,
  RESET_PAGE_SEARCH_PARAM,
  TABLE_FETCH
} from "../../utils";
import {ITEMS_PER_PAGE, PARAM, VAR} from "../../const";
import {ORDER_STATE, OrderDto, PAGE_SEARCH_OPTION} from "../../types";
import {useNavigate, useParams} from "react-router-dom";
import {useResetRecoilState} from "recoil";
import {currentUser} from "../../state";
import dayjs from "dayjs";

export const OrderList = () => {
  // Recoil
  const resetUserState = useResetRecoilState(currentUser);

  // i18n
  const { t } = useTranslation();

  // navigate
  const navigate = useNavigate();

  // UserType
  const {userType} = useParams();
  // const userType = params.userType ? params.userType : 'Client';

  // const searchParams = new URLSearchParams(window.location.search);
  const [searchParams, setSearchParams] = useState(new URLSearchParams());
  // OrderState default settings
  const [selectedOrderState, setSelectedOrderState] = useState<string[]>([]);

  // datasource
  const [tableDataSource, setTableDataSource] = useState([]);

  interface refundModalInfoType {
    open:boolean,
    orderId?:number
  }

  const [ refundModalInfo, setRefundModalInfo] = useState<refundModalInfoType>({
    open:false,
  })
  const [reason, setReason] = useState<string>('');

  // search options
  interface searchOptionType extends PAGE_SEARCH_OPTION {
    orderState?:string
  }
  const [searchOptions, setSearchOptions] = useState<searchOptionType>(
    {
      currentPage: 1,
      totalElements: 0,
      text: new Map(),
      last: new Date()
    }
  );

  // Order State Checkbox options
  const orderStateOptions = [
    {label: t('order.state.order'), value: VAR.ORDER_STATE_ORDER},
    {label: t('order.state.confirm'), value: VAR.ORDER_STATE_CONFIRM},
    {label: t('order.state.resend'), value: VAR.ORDER_STATE_RESEND},
    {label: t('order.state.close'), value: VAR.ORDER_STATE_CLOSE},
  ];


  interface PartnerParam {
    type: string,
    partnerType: number,
    name: string,
    email: string,
    workPlace: string
  }

  //add modal
  const [ userAddModalOpen, setUserAddModalOpen ] = useState<boolean>(false);
  const [ userAddForm] = Form.useForm();
  ////////////////////////////////////////////////////

  const resetSearchParam = () => {
    const param = new URLSearchParams();
    RESET_PAGE_SEARCH_PARAM(param, searchOptions); // set default search param

    if (!NULL_OR_EMPTY_STRING(searchOptions.orderState)) {
      param.append('filter', `state~${searchOptions.orderState}`)
    }

    setSearchParams(param);
  }

  useEffect(() => {
    resetSearchParam();
  }, [searchOptions.last]);

  useEffect(() => {
    if (!searchParams || searchParams.size === 0) return; // searchParam이 초기화 되지 않았을 때
    TABLE_FETCH(
      `${API.PRODUCT}/order?filter=issuer.type:${userType}&${searchParams.toString()}`,
      setTableDataSource, searchOptions, setSearchOptions,
      resetUserState, t("warning.signin.fail")
    );
  }, [searchParams, userType]);


  // First Loading
  useEffect(() => {
    // query parameter Setting
    const param = new URLSearchParams(new URL(window.location.href).search);

    // Order State checkbox
    const orderStateParams = param.getAll(PARAM.ORDER_STATE);
    setSelectedOrderState(orderStateParams);

    // reset search param
    resetSearchParam();
  }, []);


  const onOrderStateChange = (checkedValues: CheckboxValueType[]) => {
    const checkedStr = checkedValues.join(';');
    const checkedStrArr = checkedValues.map(v => v.toString());
    if (NULL_OR_EMPTY_STRING(checkedStr)) {
      setSearchOptions({...searchOptions, currentPage: 1, orderState: undefined, last: new Date()});
      setSelectedOrderState([]);
    } else {
      setSearchOptions({...searchOptions, currentPage: 1, orderState: checkedStr, last: new Date()});
      setSelectedOrderState(checkedStrArr);
    }
  }

  // 주문 상태 변경
  const onNextStateClick = (orderId:number|undefined, nextState: number) => {
    console.log(orderId, nextState);

    fetch(`${API.PRODUCT}/order/${orderId}/${nextState}`, {
      method: "PUT",
      headers: HEADER(),
    })
      .then(res => CHECK_RESPONSE(res, resetUserState))
      .then(res => {
        setSearchOptions({...searchOptions, last: new Date()}) // Reload pages
      })
      .catch(error => {
        message.open({type: 'error', content: ERROR_MESSAGE(error, t("warning.unknown"))});
      });
  }

  // Row double click (상세 페이지로 이동)
  const onRowDoubleClick = (orderDto: OrderDto) => {
    navigate(`/order/${orderDto.id}`);
  }

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: string,
  ) => {
    confirm();
    if (!!selectedKeys && selectedKeys.length > 0) {
      searchOptions.text.set(dataIndex, selectedKeys[0]);
      setSearchOptions({...searchOptions, last: new Date()});
    }
  };
  const handleReset = (dataIndex:string, clearFilters: () => void) => {
    clearFilters();
    if(searchOptions.text.has(dataIndex)) {
      searchOptions.text.delete(dataIndex);
      setSearchOptions({...searchOptions, last: new Date()});
    }
  };

  const getColumnSearchProps = (dataIndex: string): ColumnType<OrderDto> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }:FilterDropdownProps) => {
      return (
        <div style={{padding: 8}} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            style={{marginBottom: 8, display: 'block'}}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
              icon={<SearchOutlined/>}
              size="small"
              style={{width: 90}}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(dataIndex, clearFilters)}
              size="small"
              style={{width: 90}}
            >
              Reset
            </Button>
          </Space>
        </div>
      )
    },
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    render: (text) => {
      const searchText = searchOptions.text.get(dataIndex);
      return (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[!!searchText?searchText:'']}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      )},
  });

  const handleOk = () => {
    console.log({reason});
    console.log( `${refundModalInfo?.orderId}`);

    if(reason.trim() !== ''){

      fetch(`${API.PRODUCT}/order/${refundModalInfo?.orderId}/${ORDER_STATE.REFUND}`, {
        method: "PUT",
        headers: HEADER(),
        body: `${reason}`
      })
        .then(res => CHECK_RESPONSE(res, resetUserState))
        .then(res => {
          setSearchOptions({...searchOptions, last: new Date()}) // Reload pages
          setReason('');
          setRefundModalInfo(
            {
              open:false
            }
          )
        })
        .catch(error => {
          message.open({type: 'error', content: ERROR_MESSAGE(error, t("warning.unknown"))});
        });
    }else{
      message.error('환불 사유를 입력해주세요.')
    }

  };

  const handleCancel = () => {
    setReason('');
    setRefundModalInfo(
      {
        open:false
      }
    )
  };

  const UserAddButton = () => {
    if(userType === 'Partner'){
      return <Flex justify={"flex-end"}>
            <Button
                type="primary"
                icon={<UserAddOutlined />}
                onClick={() => setUserAddModalOpen(true)}
                size="middle"
                style={{width: 90}}
            >
              추가
            </Button>
      </Flex>
    }else {
      return null;
    }
  }

  const setUserAddModalClose = () => {
    userAddForm.resetFields();
    setUserAddModalOpen(false);
  };

  const onFinish = (values: any) => {
    fetch(`${API.USER}`, {
      method: "POST",
      headers: HEADER(),
      body: JSON.stringify({
        type: "Partner",
        partnerType:1,
        name: values.name,
        email: values.email,
        workPlace: values.workPlace
      } as PartnerParam)
    })
        .then(res => CHECK_RESPONSE(res, resetUserState))
        .then(res => {
          setUserAddModalClose();
          setSearchOptions({...searchOptions, last: new Date()}) // Reload pages
        })
        .catch(error => {
          message.open({type: 'error', content: ERROR_MESSAGE(error, t("warning.unknown"))});
        })
  };

  ////////////////////////////////////////////////////
  // Table description
  ////////////////////////////////////////////////////
  const orderColumDefine: ColumnsType<OrderDto> = [
    {
      title: t("table.title.orderNumber"),
      dataIndex: 'id',
      key: 'id',
      sorter: true
    },
    // {
    //   title: t("table.title.product"),
    //   dataIndex: 'product',
    //   key: 'product',
    //   render: (_, { product }) => {
    //     return (
    //       <>
    //         {product.name}
    //       </>
    //     )
    //   }
    // },
    {
      title: t("table.title.email"),
      dataIndex: 'issuer.email',
      key: 'issuer.email',
      ...getColumnSearchProps('issuer.email'),
      render: (_, { issuer }) => {
        const searchText = searchOptions.text.get('issuer.email');
        return (
            <>
              <Highlighter
                  highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                  searchWords={[!!searchText?searchText:'']}
                  autoEscape
                  textToHighlight={issuer.email ? issuer.email.toString() : ''}
              />
            </>
        )
      }
    },
    {
      title: t("table.title.issuer"),
      dataIndex: 'issuer.name',
      key: 'issuer.name',
      ...getColumnSearchProps('issuer.name'),
      render: (_, { issuer }) => {
        const searchText = searchOptions.text.get('issuer.name');
        return (
          <>
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[!!searchText?searchText:'']}
              autoEscape
              textToHighlight={issuer.name ? issuer.name.toString() : ''}
            />
          </>
        )
      }
    },
    userType === 'Client' ?
      {
        title: t("table.title.key"),
        dataIndex: 'issuer.key',
        key: 'issuer.key',
        ...getColumnSearchProps('issuer.key'),
        render: (_, { issuer }) => {
          const searchText = searchOptions.text.get('issuer.key');
          return (
            <>
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[!!searchText?searchText:'']}
                autoEscape
                textToHighlight={issuer.key ? issuer.key.toString() : ''}
              />
            </>
          )
        }
      }
      :
      {
        title: t("table.title.workplace"),
        dataIndex: 'issuer.workPlace',
        key: 'issuer.workPlace',
        ...getColumnSearchProps('issuer.workPlace'),
        render: (_, { issuer }) => {
          const searchText = searchOptions.text.get('issuer.workPlace');
          return (
            <>
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[!!searchText?searchText:'']}
                autoEscape
                textToHighlight={issuer.workPlace ? issuer.workPlace.toString() : ''}
              />
            </>
          )
        }
      }
    ,
    {
      title: t("table.title.issuedAt"),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      render: (_, {createdAt}) => {
        return (
          <>
            {!!createdAt ? dayjs(createdAt).format('YYYY-MM-DD') : ''}
          </>
        )
      }
    },
    {
      title: t("table.title.attaches"),
      dataIndex: 'attaches',
      key: 'attaches',
      sorter: true,
      render: (_, {attaches}) => {
        return (
          <>
            {attaches.length}
          </>
        )
      }
    },
    {
      title: t("table.title.downloadCount"),
      dataIndex: 'downloadCount',
      key: 'downloadCount',
      sorter: true,
    },
    {
      title: t("table.title.orderState"),
      dataIndex: 'state',
      key: 'state',
      sorter: true,
      render: (_, {state, stateName}) => {
        const color = ORDER_STATE_COLOR(state);
        return (
          <Tag color={color} key={state}>
            {stateName}
          </Tag>
        )
      }
    },
    {
      title: t("table.title.available"),
      dataIndex: 'available',
      key: 'available',
      render: (_, {id, available}) => {
        return (
            available.map(st => (
                    <Tag className={"tag-mouse-up"} color={ORDER_STATE_COLOR(st.state)} key={st.stateName}
                         onClick={() =>
                             st.state === ORDER_STATE.REFUND ? setRefundModalInfo({
                                   open:true,
                                   orderId:id
                                 }) :
                                 onNextStateClick(id, st.state)
                         }
                    >
                      {st.stateName}
                    </Tag>
                )
            )
        )
      }
    },
    {
      title: t("table.title.lastUpdate"),
      dataIndex: 'lastStateUpdatedAt',
      key: 'lastStateUpdatedAt',
      sorter: true,
      render: (_, {lastStateUpdatedAt}) => {
        return (
          <>
            {!!lastStateUpdatedAt ? dayjs(lastStateUpdatedAt).format('YYYY-MM-DD HH:mm:ss') : ''}
          </>
        )
      }
    }
  ]


  return (
    <>
      <main>
        <div className={"margin-tb"}>
          <Row gutter={[16,16]}>
            <Col span={12}>
              <Space>
                <Checkbox.Group
                  options={orderStateOptions}
                  defaultValue={[]}
                  value={selectedOrderState}
                  onChange={onOrderStateChange} />
              </Space>
            </Col>
            <Col span={12}>
              <UserAddButton/>
            </Col>
          </Row>
        </div>

        <Table
          columns={orderColumDefine}
          dataSource={[...tableDataSource]}
          rowKey='id'
          onChange={
            (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<OrderDto> | any) =>
              ON_TABLE_CHANGE(searchOptions, setSearchOptions, pagination, filters, sorter)
          }
          onRow={(record, rowIndex) => {
            return {
              onDoubleClick: (evt) => onRowDoubleClick(record),
            }
          }}
          pagination={{
            current: searchOptions.currentPage,
            total: searchOptions.totalElements,
            pageSize: ITEMS_PER_PAGE,
            position: ['bottomCenter'],
            hideOnSinglePage: false,
          }}
        />
      </main>



      <Modal
        title={t("message.required.refund.0")}
        open={refundModalInfo.open}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={"확인"}
        cancelText={"취소"}
      >
        <p>{t("message.required.refund.0")}{t("message.required.refund.1")}</p>
        <p>
          <Input size="large" placeholder="사유를 입력해주세요." value={reason} onChange={(e) =>{setReason(e.target.value);}} />
        </p>
      </Modal>


        <Modal
            title={t("title.partner_add_form")}
            open={userAddModalOpen}
            onCancel={setUserAddModalClose}
            footer={[]}
        >
          <Form
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              name={"userAdd"}
              onFinish={onFinish}
              autoComplete={"off"}
              form={userAddForm}
          >
            <div className={"margin-tb"}>
              <section className={"width100"}>
                <Form.Item
                    name="email"
                    label={t("user.email")}
                    rules={[
                      {
                        required: true,
                        message: t("message.required.email"),
                      },
                      () => ({
                        validator(_, value) {
                          const regx = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;

                      if(!value || regx.test(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(t("message.required.emailRegx")));
                    },
                  }),
                ]}
              >
                <Input
                  className={"des_input"}
                  //   prefix={<MailOutlined className="site-form-item-icon" />}
                  placeholder={t("user.email")}
                  // disable copy & paste
                  onPaste={(e)=>{
                    e.preventDefault()
                    return false;
                  }}
                  onCopy={(e)=>{
                    e.preventDefault()
                    return false;
                  }}
                />
              </Form.Item>
            </section>


            <section className={"width100"}>
              <Form.Item
                name="name"
                label={t("user.name")}
                rules={[
                  {
                    required: true,
                    message: t("message.required.name"),
                  },
                ]}
              >
                <Input
                  className={"des_input"}
                  //   prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder={t("user.name")}
                />
              </Form.Item>
            </section>

            <section className={"width100"}>
              <Form.Item
                name="workPlace"
                label={t("user.workPlace")}
                rules={[
                  {
                    required: true,
                    message: t("message.required.workPlace"),
                  },
                ]}
              >
                <Input
                  className={"des_input"}
                  //   prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder={t("user.workPlace")}
                />
              </Form.Item>
            </section>

          </div>
          <Flex justify={"flex-end"} gap={"small"}>
            <Button onClick={()=> setUserAddModalClose() } type={"default"} htmlType={"button"} >
              취소
            </Button>
            <Button type={"primary"} htmlType="submit" >
              등록
            </Button>
          </Flex>
        </Form>

      </Modal>
    </>
  )
}