
export const LANGUAGES = [
    { label: "한국어", code: "ko" },
    { label: "English", code: "en" },
];

export enum MAPPING {
    normal = 'normal',
    detail = 'detail',
    list = 'list',
    id = 'id'
}

// Pege items (한페이지에 몇개를 보여줄 것인가)
export const ITEMS_PER_PAGE = 30;

// Colors
export enum COLOR {
    gray = 'lightGray',
    red_01 = 'red',
    red_02 = 'orangeRed',
    red_03 = 'coral',
    yellow_00 = 'gold',
    yellow_01 = 'lemonChiffon',
    yellow_03 = 'yellow',
    purple_01 = 'purple',
    purple_02 = 'magenta',
    purple_03 = 'violet',
    green_01 = 'green',
    green_02 = 'limeGreen',
    green_03 = 'chartreuse',
    blue_01 = 'blue',
    blue_02 = 'dodgerBlue',
    blue_03 = 'skyBlue',
}

export enum CHART_COLOR {
    color_01 = 'rgba(255, 99, 132, 0.2)',
    color_02 = 'rgba(255, 159, 64, 0.2)',
    color_03 = 'rgba(255, 205, 86, 0.2)',
    color_04 = 'rgba(75, 192, 192, 0.2)',
    color_05 = 'rgba(54, 162, 235, 0.2)',
    color_06 = 'rgba(153, 102, 255, 0.2)',
    color_07 = 'rgba(201, 203, 207, 0.2)',
    MAGENTA = 'rgba(194,0,120, 0.9)',
    LAVENDER='rgba(199,159,239, 0.9)',
    TEAL='rgba(2,147,134, 0.2)',
    BLUE ='rgba(3,67,223, 0.2)',
}

export enum CHART_BORDER {
    color_01 = 'rgb(255, 99, 132)',
    color_02 = 'rgb(255, 159, 64)',
    color_03 = 'rgb(255, 205, 86)',
    color_04 = 'rgb(75, 192, 192)',
    color_05 = 'rgb(54, 162, 235)',
    color_06 = 'rgb(153, 102, 255)',
    color_07 = 'rgb(201, 203, 207)',

    PUPLE = '#7e1e9c',
    GREEN ='#15b01a',
    BLUE ='#0343df',
    PINK = '#ff81c0',
    BROWN = '#653700',
    RED='#e50000',
    TEAL='#029386',
    ORANGE='#f97306',
    MAGENTA='#c20078',
    YELLOW='#ffff14',
    GRAY='#929591',
    VIOLET='#9a0eea',
    TURQUOISE='#06c2ac',
    LAVENDER='#c79fef',
    TAN='#d1b26f',
    CYAN='#00ffff',
    AQUA='#13eac9',
    MAUVE='#ae7181',
    MAROON='#650021',
    OLIVE='#6e750e',
    SALMON='#ff796c',
    BEIGE='#e6daa6',
    LILAC='#cea2fd',
    BLACK='#000000',
    PEACH='#ffb07c',
    LIME='#aaff32',
    INDIGO='#380282'






}


// Parameter names
export enum PARAM {
    ORDER_STATE = 'orderState',
    SEARCH_PREFIX = 's_'
}

// Const variables
export enum VAR {
    ORDER_STATE_ORDER = 'ORDER',
    ORDER_STATE_CONFIRM = 'CONFIRM',
    ORDER_STATE_RESEND = 'RESEND',
    ORDER_STATE_CLOSE = 'SEND;CLOSE',
}

export enum SPAN_CLASS{
    ORDER='order_stage order_stage1',
    CONFIRM='order_stage order_stage2',
    UNCONFIRMED='order_stage order_stage4',
    REJECT='order_stage order_stage4',
    SEND='order_stage order_stage2',
    RESEND='order_stage order_stage3',
    CANCEL='order_stage order_stage4',
    CLOSE='order_stage order_stage4',
    REFUND='order_stage order_stage4'
}

export enum BUTTON_CLASS{
    ORDER='des_btn_default des_btn_stage_default des_btn_stage1',
    CONFIRM='des_btn_default des_btn_stage_default des_btn_stage2',
    UNCONFIRMED='des_btn_default des_btn_stage_default des_btn_stage4',
    REJECT='des_btn_default des_btn_stage_default des_btn_stage4',
    SEND='des_btn_default des_btn_stage_default des_btn_stage2',
    RESEND='des_btn_default des_btn_stage_default des_btn_stage3',
    CANCEL='des_btn_default des_btn_stage_default des_btn_stage2',
    CLOSE='des_btn_default des_btn_stage_default des_btn_stage4',
    REFUND='des_btn_default des_btn_stage_default des_btn_stage4'
}

export enum DATE_PICKER_TYPE{
    YEAR='year',
    MONTH='month',
    DATE='date'
}

export enum DATE_FORMAT{
    YYYY='YYYY',
    YYYY_MM='YYYY-MM',
    YYYY_MM_DD='YYYY-MM-DD',
    MM='MM',
    DD='DD'
}