import React, {useEffect} from 'react';

import {BrowserRouter, Route, Routes} from "react-router-dom";

import {MainMenu} from "./components/MainMenu"
import {About} from "./components/root/About";
import {Home} from "./components/root/Home";
import {SignIn} from "./components/root/SignIn";
import {useRecoilValue} from "recoil";
import {currentUser} from "./state";
import AuthRoute, {AuthRouteProps} from "./AuthRoute";
import {UserAdminList} from "./components/user/UserAdminList";
import {UserClientList} from "./components/user/UserClientList";
import {OrderList} from "./components/product/OrderList";
import {OrderDetail} from "./components/product/OrderDetail";
import {Invalid} from "./components/root/Invalid";
import {ProductList} from "./components/product/ProductList";
import {ProductDetail} from "./components/product/ProductDetail";

function App() {
  // recoil
  const userState = useRecoilValue(currentUser);

  useEffect(() => {
    console.log("currentMenu: ", userState.menu, " auth:", userState.authed);
  }, [userState.menu, userState.authed]);

  const defaultAuthRouteProps: Omit<AuthRouteProps, 'outlet'> = {
    isAuthenticated: !!userState.userId,
    authenticationPath: '/login',
  };

  return (
    <BrowserRouter>
      <MainMenu />
      <Routes>
        <Route path="/" element={<AuthRoute {...defaultAuthRouteProps} outlet={<Home />} />} />
        <Route path="/about" element={<AuthRoute {...defaultAuthRouteProps} outlet={<About />} />} />

        <Route path="/user/admin/list" element={<AuthRoute {...defaultAuthRouteProps} outlet={<UserAdminList />} />} />
        <Route path="/user/client/list" element={<AuthRoute {...defaultAuthRouteProps} outlet={<UserClientList />} />} />

        <Route path="/product/list" element={<AuthRoute {...defaultAuthRouteProps} outlet={<ProductList />} />} />
        <Route path="/product/:id" element={<AuthRoute {...defaultAuthRouteProps} outlet={<ProductDetail />} />} />

        <Route path="/order/list/:userType" element={<AuthRoute {...defaultAuthRouteProps} outlet={<OrderList />} />} />
        <Route path="/order/:id" element={<AuthRoute {...defaultAuthRouteProps} outlet={<OrderDetail />} />} />

        <Route path="/login" element={<SignIn />} />

        <Route path={"*"} element={<Invalid />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
