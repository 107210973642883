import {useTranslation} from "react-i18next";
import {API} from "../../config";
import {TABLE_FETCH} from "../../utils";
import {PaginationProps, Segmented, SegmentedProps, Table, Tag} from "antd";
import {useEffect, useState} from "react";
import {ITEMS_PER_PAGE, MAPPING} from "../../const";
import {UserDto} from "../../types";
import {ColumnsType} from "antd/es/table";
import {useResetRecoilState} from "recoil";
import {currentUser} from "../../state";
import dayjs from "dayjs";

export const UserAdminList = () => {
  // Recoil
  const resetUserState = useResetRecoilState(currentUser);

  // i18n
  const { t } = useTranslation();

  // const searchParams = new URLSearchParams(window.location.search);
  const [searchParams, setSearchParams] = useState(new URLSearchParams());

  // datasource
  const [tableDataSource, setTableDataSource] = useState([]);

  // search options
  interface searchOptionType {
    currentPage: number,
    totalElements: number,
    userType: string,
    last: Date
  }
  const [searchOptions, setSearchOptions] = useState<searchOptionType>(
    {
      currentPage: 1,
      totalElements: 0,
      userType: 'All',
      last: new Date()
    }
  );

  const resetSearchParam = () => {
    const param = new URLSearchParams();
    param.append('type', 'User');
    param.append('mapping', MAPPING.normal);
    param.append('page', (searchOptions.currentPage-1).toString());
    param.append('size', ITEMS_PER_PAGE.toString());
    if (!searchOptions.userType || searchOptions.userType === 'All') {
      param.append('filter', 'type~Admin;Operator');
    } else {
      param.append('filter', `type:${searchOptions.userType}`);
    }
    setSearchParams(param);
  }

  useEffect(() => {
    resetSearchParam();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchOptions.last]);

  useEffect(() => {
    if (!searchParams || searchParams.size === 0) return; // searchParam이 초기화 되지 않았을 때
    TABLE_FETCH(
      `${API.USER}?${searchParams.toString()}`,
      setTableDataSource, searchOptions, setSearchOptions,
      resetUserState, t("warning.signin.fail")
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  // First Loading
  useEffect(() => {
    resetSearchParam();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const onPageChange: PaginationProps['onChange'] = (pageNumber) => {
    console.log('Page: ', pageNumber);
    if (pageNumber !== searchOptions.currentPage) {
      setSearchOptions({...searchOptions, currentPage: pageNumber, last: new Date()});
    }
  };

  const onUserTypeChange: SegmentedProps['onChange'] = (selected) => {
    console.log("UserType:", selected);
    if (selected !== searchOptions.userType) {
      setSearchOptions({...searchOptions, userType: selected.toString(), last: new Date()});
    }
    console.log(searchOptions.userType);
  }

  ////////////////////////////////////////////////////
  // Table description
  ////////////////////////////////////////////////////
  const adminUserColumDefine: ColumnsType<UserDto> = [
    {
      title: t("table.title.type"),
      dataIndex: 'type',
      key: 'type',
      render: (_, { type }) => {
        let color = type === 'Admin' ? 'red' : 'blue';
        return (
          <Tag color={color} key={type}>
            {type.toUpperCase()}
          </Tag>
        )
      }
    },
    {
      title: t("table.title.name"),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t("table.title.id"),
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: t("table.title.email"),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t("table.title.lastLogin"),
      dataIndex: 'lastLoginAt',
      key: 'lastLoginAt',
      render: (_, {lastLoginAt}) => {
        return (
          <>
            {!!lastLoginAt ? dayjs(lastLoginAt).format('YYYY-MM-DD HH:mm:ss') : ''}
          </>
        )
      }
    }
  ]

  return (
    <>
      <main>
        <Segmented
          options={[
            {label: t('search.user.type.all'), value: 'All'},
            {label: t('search.user.type.operator'), value: 'Operator'},
            {label: t('search.user.type.admin'), value: 'Admin'}
          ]}
          defaultValue='All'
          onChange={onUserTypeChange}
        />
        <Table
          columns={adminUserColumDefine}
          dataSource={[...tableDataSource]}
          rowKey='id'
          pagination={{
            current: searchOptions.currentPage,
            total: searchOptions.totalElements,
            pageSize: ITEMS_PER_PAGE,
            position: ['bottomCenter'],
            hideOnSinglePage: false,
            onChange: onPageChange
          }}
        />
      </main>
    </>
  )
}