import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {
  AUDIT_HISTORY_COLOR, CHECK_RESPONSE,
  DOWNLOAD_FILE,
  EQUAL,
  ERROR_MESSAGE,
  FILE_AVATA_ICON,
  HEADER,
  JSON_TO_STRING,
  MK_DOWNLOAD_URL,
  NUMBER,
  PRODUCT_STATE_COLOR,
  STRING
} from "../../utils";
import {useTranslation} from "react-i18next";
import {
  Avatar,
  Descriptions,
  Divider,
  List,
  message,
  Tag,
  Timeline,
  TimelineItemProps,
  Upload,
  UploadFile,
  UploadProps
} from "antd";
import {API} from "../../config";
import {AuditDto, ChargeInfo, FileDto, FileListDto, ProductDto, ProductIdDto} from "../../types";
import {DescriptionsItemType} from "antd/es/descriptions";
import {InboxOutlined} from "@ant-design/icons";
import {useResetRecoilState} from "recoil";
import {currentUser} from "../../state";
import dayjs from "dayjs";

const { Dragger } = Upload;

export const ProductDetail = () => {
  // Recoil
  const resetUserState = useResetRecoilState(currentUser);

  // i18n
  const { t } = useTranslation();

  // interface
  interface ProductAuditDto extends AuditDto {
    product: ProductIdDto,
  }

  // Order ID
  const params = useParams();
  const productId = params.id;

  // Reload
  const [reload, setReload] = useState<Date>();
  // Order detail
  const [productInfo, setProductInfo] = useState<ProductDto>();
  // Order history
  const [productHistory, setProductHistory] = useState<ProductAuditDto[]>([]);

  // Description
  const [items, setItems] = useState<DescriptionsItemType[]>([]);
  // History
  const [histories, setHistories] = useState<TimelineItemProps[]>([]);

  // update Product
  const [attachFile, setAttachFile] = useState<FileDto>();

  // File list
  const [fileList, setFileList] = useState<UploadFile[]>([])

  // Remove file ID
  const [removeFileId, setRemoveFileId] = useState<number>();

  // Download file ID
  const [downloadFileInfo, setDownloadFileInfo] = useState<FileListDto>();

  // Upload properties
  const props: UploadProps = {
    name: 'file',
    multiple: false,
    action: `${API.FILE}`,
    showUploadList: false,
    // fileList: fileList,
    onChange(info) {
      const { status } = info.file;

      console.log(status);
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        if (productInfo) {
          setAttachFile(info.file.response);
        }
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
      // setFileList({...info.fileList});
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };


  // Load Order Info
  const loadProductInfo = () => {
    fetch(`${API.PRODUCT}?filter=id:${productId}&mapping=detail&size=1`, {
      method: "GET",
      headers: HEADER(),
    })
      .then(res => CHECK_RESPONSE(res, resetUserState))
      .then(res => {
        if (res.totalElements > 0) {
          const product = res.content[0] as ProductDto;
          const newFileList: UploadFile[] = [];
          if (product.serviceFiles) {
            product.serviceFiles.forEach(f => newFileList.push({
              uid: STRING(f.id),
              name: f.name,
              status: 'done',
              url: f.url,
            } as UploadFile));
          }

          // setFileList(newFileList);
          setProductInfo(product);
        }
      })
      .catch(error => {
        message.open({type: 'error', content: ERROR_MESSAGE(error, t("warning.unknown"))});
      });
  }

  const loadProductHistory = () => {
    fetch(`${API.AUDIT}/product?filter=product.id:${productId}&size=100&sort=id,desc`, {
      method: "GET",
      headers: HEADER(),
    })
      .then(res => CHECK_RESPONSE(res, resetUserState))
      .then(res => {
        if (res.totalElements > 0) {
          setProductHistory(res.content);
        }
      })
      .catch(error => {
        message.open({type: 'error', content: ERROR_MESSAGE(error, t("warning.unknown"))});
      });
  }

  const fetchUpdateProduct = (dto:ProductDto) => {
    fetch(`${API.PRODUCT}/${productId}`, {
      method: "PUT",
      headers: HEADER(),
      body: JSON_TO_STRING(dto)
    })
      .then(res => CHECK_RESPONSE(res, resetUserState))
      .then(res => {
        setReload(new Date());
      })
      .catch(error => {
        message.open({type: 'error', content: ERROR_MESSAGE(error, t("warning.unknown"))});
      });
  }

  // FILE 삭제
  const removeFile = (fileId:number) => {
    if (!!productInfo && !!fileId) {
      const attaches:FileListDto[] = !!productInfo.serviceFiles ? productInfo.serviceFiles.filter((f:FileListDto) => !EQUAL(f.id, fileId)) : [] as FileListDto[];
      const detaches:FileListDto[] = !!productInfo.deprecatedFiles ? productInfo.deprecatedFiles.filter(() => true) : [] as FileListDto[];
      detaches.push({
        id:  NUMBER(fileId),
        name: '',
        url: ''
      } as FileListDto);
      const dto: ProductDto = {
        type: productInfo.type,
        serviceFiles: attaches,
        deprecatedFiles: detaches
      } as ProductDto;

      fetchUpdateProduct(dto);
    }
  }

  // File의 유/무료 상태 변경
  const updateFileCharge = (info:ChargeInfo) => {
    fetch(`${API.PRODUCT}/${productId}/attaches`, {
      method: "PUT",
      headers: HEADER(),
      body: JSON_TO_STRING([info])
    })
      .then(res => CHECK_RESPONSE(res, resetUserState))
      .then(res => {
        message.success(t('success.ok'));
        setReload(new Date());
      })
      .catch(error => {
        message.open({type: 'error', content: ERROR_MESSAGE(error, t("warning.unknown"))});
      });
  }


  useEffect(() => {
    if (removeFileId) removeFile(removeFileId);
  }, [removeFileId]);

  useEffect(() => {
    if (downloadFileInfo) DOWNLOAD_FILE(downloadFileInfo, resetUserState, t("warning.unknown"));
  }, [downloadFileInfo]);


  // Refresh data
  useEffect(() => {
    loadProductInfo();
    loadProductHistory();
  }, [reload]);


  // File upload 성공 시 Product update
  useEffect(() => {
    if (!!productInfo && !!attachFile) {
      const attaches = !!productInfo.serviceFiles ? productInfo.serviceFiles.filter(()=>true) : [] as FileListDto[];
      attaches.push(JSON.parse(JSON.stringify(attachFile)));
      const dto:ProductDto = {
        type: productInfo.type,
        serviceFiles: attaches
      } as ProductDto;

      fetchUpdateProduct(dto);
    }
  }, [attachFile]);

  // Page loadding이 완료
  useEffect(() => {
    setReload(new Date())
  }, []);

  // Order 정보 조회
  useEffect(() => {
    if (!!productInfo) {
      setItems([
        {
          key: 'product.name',
          label: t('table.title.product'),
          children: <p>{productInfo.name}</p>,
        },
        {
          key: 'owners.name',
          label: t('table.title.manager'),
          children: <p>{
            !!productInfo.owners
              ? productInfo.owners.map(u => u.name).join(", ")
              : '-'
          }</p>,
        },
        {
          key: 'createdAt',
          label: t('table.title.createdAt'),
          children: <p>{!!productInfo.createdAt ? dayjs(productInfo.createdAt).format('YYYY-MM-DD HH:mm:ss') : ''}</p>,
        },
        {
          key: 'state',
          label: t('table.title.orderState'),
          children: (
            <p>
              <Tag color={PRODUCT_STATE_COLOR(productInfo.state)} key={productInfo.state}>
                {productInfo.stateName}
              </Tag>
            </p>
          ),
        },
        {
          key: 'serviceFiles',
          label: t("table.title.attaches"),
          span: 2,
          children: (
            <>
                <Dragger {...props}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-hint">
                    {t("file.upload")}
                  </p>
                </Dragger>
              <Divider dashed/>
                <List
                  dataSource={productInfo.serviceFiles}
                  renderItem={(item) => (
                    <List.Item actions={[<a onClick={() => setRemoveFileId(item.id)} key='delete-file'>delete</a>]}>
                      <List.Item.Meta
                        avatar={
                          <Avatar src={FILE_AVATA_ICON(item)}
                                  onClick={() => updateFileCharge({
                                    id: item.id,
                                    charged: !item.charged  // toggle
                                  } as ChargeInfo)}
                          />}
                        title={item.charged ? t("file.charged") : t("file.free")}
                        description={item.charged ? `${t("file.maxDownload")}: ${item.maxDownload}` : ''}
                      />
                      <a onClick={() => setDownloadFileInfo({
                        id: item.id,
                        name: item.name,
                        url: MK_DOWNLOAD_URL(item.url),
                        contentType: item.contentType
                      } as FileListDto)}>{item.name}</a>
                    </List.Item>
                  )}
                />
            </>
          )
        },
        {
          key: 'lastUpdatedAt',
          label: t('table.title.lastUpdate'),
          children: <p>{!!productInfo.updatedAt ? dayjs(productInfo.updatedAt).format('YYYY-MM-DD HH:mm:ss') : ''}</p>,
        },
      ]);
    }
  }, [productInfo]);

  // Order History 조회
  useEffect(() => {
    if (!!productHistory && productHistory.length > 0) {
      setHistories(productHistory.map(h => ({
          color: AUDIT_HISTORY_COLOR(h.auditType),
          label: dayjs(h.createdAt).format('YYYY-MM-DD HH:mm:ss'),
          children: (
            <>
              <p>{!!h.user ? `${STRING(h.user.type)}:${STRING(h.user.name)}` : ''}</p>
              <p>{h.message}</p>
            </>
          ),
        }))
      );
    }
  }, [productHistory]);


  return(
    <>
      <Descriptions title={t('title.product_info')}  bordered items={items} />
      <br/>
      <Timeline mode='left' items={histories} />
    </>
  )
}