import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {
  AUDIT_HISTORY_COLOR, CHECK_RESPONSE,
  DOWNLOAD_FILE,
  ERROR_MESSAGE, FILE_AVATA_ICON,
  HEADER,
  MK_DOWNLOAD_URL,
  ORDER_STATE_COLOR,
  STRING
} from "../../utils";
import {useTranslation} from "react-i18next";
import {Avatar, Descriptions, Input, List, message, Modal, Tag, Timeline, TimelineItemProps} from "antd";
import {API} from "../../config";
import {AUDIT_TYPE, AuditDto, FileListDto, ORDER_STATE, OrderDto, OrderIdDto} from "../../types";
import {DescriptionsItemType} from "antd/es/descriptions";
import {useResetRecoilState} from "recoil";
import {currentUser} from "../../state";
import dayjs from "dayjs";

export const OrderDetail = () => {
  // Recoil
  const resetUserState = useResetRecoilState(currentUser);

  // i18n
  const { t } = useTranslation();

  // interface
  interface OrderAuditDto extends AuditDto {
    order: OrderIdDto,
    raw?:string
  }

  // Order ID
  const params = useParams();
  const orderId = params.id;

  // Reload
  const [reload, setReload] = useState<Date>();
  // Order detail
  const [orderInfo, setOrderInfo] = useState<OrderDto>();
  // Order history
  const [orderHistory, setOrderHistory] = useState<OrderAuditDto[]>([]);

  // Description
  const [items, setItems] = useState<DescriptionsItemType[]>([]);
  // History
  const [histories, setHistories] = useState<TimelineItemProps[]>([]);
  // Download file ID
  const [downloadFileInfo, setDownloadFileInfo] = useState<FileListDto>();

  interface refundModalInfoType {
    open:boolean,
    orderId?:number
  }

  const [ refundModalInfo, setRefundModalInfo] = useState<refundModalInfoType>({
    open:false,
  })
  const [reason, setReason] = useState<string>('');

  // Load Order Info
  const loadOrderInfo = () => {
    fetch(`${API.PRODUCT}/order?filter=id:${orderId}&size=1`, {
      method: "GET",
      headers: HEADER(),
    })
      .then(res => CHECK_RESPONSE(res, resetUserState))
      .then(res => {
        if (res.totalElements > 0) {
          setOrderInfo(res.content[0]);
        }
      })
      .catch(error => {
        message.open({type: 'error', content: ERROR_MESSAGE(error, t("warning.unknown"))});
      });
  }

  const loadOrderHistory = () => {
    fetch(`${API.AUDIT}/order?mapping=detail&filter=order.id:${orderId}&size=100&sort=id,desc`, {
      method: "GET",
      headers: HEADER(),
    })
      .then(res => CHECK_RESPONSE(res, resetUserState))
      .then(res => {
        if (res.totalElements > 0) {
          setOrderHistory(res.content);
        }
      })
      .catch(error => {
        message.open({type: 'error', content: ERROR_MESSAGE(error, t("warning.unknown"))});
      });
  }

  // 주문 상태 변경
  const onNextStateClick = (orderId:number|undefined, nextState: number) => {
    fetch(`${API.PRODUCT}/order/${orderId}/${nextState}`, {
      method: "PUT",
      headers: HEADER(),
    })
      .then(res => CHECK_RESPONSE(res, resetUserState))
      .then(res => {
        message.success(t('success.ok'));
        setReload(new Date()) // Reload pages
      })
      .catch(error => {
        message.open({type: 'error', content: ERROR_MESSAGE(error, t("warning.unknown"))});
      });
  }

  useEffect(() => {
    if (downloadFileInfo) DOWNLOAD_FILE(downloadFileInfo, resetUserState, t("warning.unknown"));
  }, [downloadFileInfo]);



  const handleOk = () => {
    if(reason.trim() !== ''){
      fetch(`${API.PRODUCT}/order/${refundModalInfo?.orderId}/${ORDER_STATE.REFUND}`, {
        method: "PUT",
        headers: HEADER(),
        body: `${reason}`
      })
          .then(res => CHECK_RESPONSE(res, resetUserState))
          .then(res => {
            setReload(new Date()) // Reload pages
            setReason('');
            setRefundModalInfo(
                {
                  open:false
                }
            )
          })
          .catch(error => {
            message.open({type: 'error', content: ERROR_MESSAGE(error, t("warning.unknown"))});
          });
    }else{
      message.error('환불 사유를 입력해주세요.')
    }

  };

  const handleCancel = () => {
    setReason('');
    setRefundModalInfo(
        {
          open:false
        }
    )
  };

  // Refresh data
  useEffect(() => {
    loadOrderInfo();
    loadOrderHistory();
  }, [reload]);

  // Page loadding이 완료
  useEffect(() => {
    setReload(new Date())
  }, []);

  // Order 정보 조회
  useEffect(() => {
    if (!!orderInfo) {
      setItems([
        {
          key: 'product.name',
          label: t('table.title.product'),
          children: <p>{orderInfo.product.name}</p>,
        },
        {
          key: 'issuer.name',
          label: t('table.title.issuer'),
          children: <p>{orderInfo.issuer.name}</p>,
        },
        {
          key: 'createdAt',
          label: t('table.title.issuedAt'),
          children: <p>{!!orderInfo.createdAt ? dayjs(orderInfo.createdAt).format('YYYY-MM-DD HH:mm:ss') : ''}</p>,
        },
        {
          key: 'state',
          label: t('table.title.orderState'),
          children: (
            <p>
              <Tag color={ORDER_STATE_COLOR(orderInfo.state)} key={orderInfo.state}>
                {orderInfo.stateName}
              </Tag>
            </p>
          ),
        },
        {
          key: 'available',
          label: t("table.title.available"),
          children: (
            <p>
              {orderInfo.available.map(st => (
                <Tag className={"tag-mouse-up"} color={ORDER_STATE_COLOR(st.state)} key={st.stateName}
                     onClick={() => st.state === ORDER_STATE.REFUND ? setRefundModalInfo({
                           open:true,
                           orderId:orderInfo.id
                         }) :
                         onNextStateClick(orderInfo.id, st.state)}
                >
                  {st.stateName}
                </Tag>
              ))
              }
            </p>
          )
        },
        {
          key: 'lastStateUpdatedAt',
          label: t('table.title.lastUpdate'),
          children: <p>{!!orderInfo.lastStateUpdatedAt ? dayjs(orderInfo.lastStateUpdatedAt).format('YYYY-MM-DD HH:mm:ss') : ''}</p>,
        },
        {
          key: 'downloadCount',
          label: t('table.title.downloadCount'),
          children: <p>{orderInfo.downloadCount}</p>,
        },
        {
          key: 'attaches',
          label: t("table.title.attaches"),
          children: (
            <List
              dataSource={orderInfo.attaches}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar src={FILE_AVATA_ICON(item)}/>}
                    title={item.charged ? t("file.charged") : t("file.free")}
                    description={item.charged ? `${item.downloadCount} / ${item.maxDownload}` : ''}
                  />
                  <a onClick={() => setDownloadFileInfo({
                    id: item.id,
                    name: item.name,
                    url: MK_DOWNLOAD_URL(item.url),
                    contentType: item.contentType
                  } as FileListDto)}>{item.name}</a>
                  {/*<div className="file-revision">{item.revision}</div>*/}
                </List.Item>
              )}
            />
          )
        },
      ]);
    }
  }, [orderInfo]);

  // Order History 조회
  useEffect(() => {
    if (!!orderHistory && orderHistory.length > 0) {
      setHistories(orderHistory.map(h => ({
          color: AUDIT_HISTORY_COLOR(h.auditType),
          label: dayjs(h.createdAt).format('YYYY-MM-DD HH:mm:ss'),
          children: (
            <>
              <p>{!!h.user ? `${STRING(h.user.type)}:${STRING(h.user.name)}` : ''}</p>
              <p>{h.message}</p>
              {
                (h.auditType === AUDIT_TYPE.ORDER_RE_SEND ||  h.auditType === AUDIT_TYPE.ORDER_REFUND) ?
                    ((h.raw !== null || h.raw !== undefined) ? <p>사유: {h.raw}</p> : null) : null
              }
            </>
          ),
        }))
      );
    }
  }, [orderHistory]);


  return(
    <>
      <Descriptions title={t('title.order_info')}  bordered items={items} />
      <br/>
      <Timeline mode='left' items={histories} />

      <Modal
          title={t("message.required.refund.0") + t("message.required.refund.1")}
          open={refundModalInfo.open}
          onOk={handleOk}
          onCancel={handleCancel}
          okText={"확인"}
          cancelText={"취소"}
      >
        <p>
          <Input size="large" placeholder="사유를 입력해주세요." value={reason} onChange={(e) =>{setReason(e.target.value);}} />
        </p>
      </Modal>


    </>
  )
}