import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Highlighter from "react-highlight-words";
import {Button, Input, message, Space, Table, TablePaginationConfig, Tag} from "antd";
import {ColumnsType, ColumnType} from "antd/es/table";
import {FilterConfirmProps, FilterDropdownProps, FilterValue, SorterResult} from "antd/es/table/interface";
import {SearchOutlined} from "@ant-design/icons";

import {API} from "../../config";
import {ON_TABLE_CHANGE, PRODUCT_STATE_COLOR, RESET_PAGE_SEARCH_PARAM, TABLE_FETCH} from "../../utils";
import {ITEMS_PER_PAGE, MAPPING} from "../../const";
import {PAGE_SEARCH_OPTION, ProductDto} from "../../types";
import {useNavigate} from "react-router-dom";
import {useResetRecoilState} from "recoil";
import {currentUser} from "../../state";
import dayjs from "dayjs";

export const ProductList = () => {
  // Recoil
  const resetUserState = useResetRecoilState(currentUser);

  // i18n
  const { t } = useTranslation();

  // navigate
  const navigate = useNavigate();

  // const searchParams = new URLSearchParams(window.location.search);
  const [searchParams, setSearchParams] = useState(new URLSearchParams());

  // datasource
  const [tableDataSource, setTableDataSource] = useState([]);

  // search options
  interface searchOptionType extends PAGE_SEARCH_OPTION {
  }
  const [searchOptions, setSearchOptions] = useState<searchOptionType>(
    {
      currentPage: 1,
      totalElements: 0,
      text: new Map(),
      last: new Date()
    }
  );

  ////////////////////////////////////////////////////

  const resetSearchParam = () => {
    const param = new URLSearchParams();
    RESET_PAGE_SEARCH_PARAM(param, searchOptions); // set default search param
    param.append('mapping', MAPPING.detail);

    setSearchParams(param);
  }

  useEffect(() => {
    resetSearchParam();
  }, [searchOptions.last]);

  useEffect(() => {
    if (!searchParams || searchParams.size === 0) return; // searchParam이 초기화 되지 않았을 때
    TABLE_FETCH(
      `${API.PRODUCT}?${searchParams.toString()}`,
      setTableDataSource, searchOptions, setSearchOptions,
      resetUserState, t("warning.signin.fail")
    );
  }, [searchParams]);

  // First Loading
  useEffect(() => {
    // query parameter Setting
    const param = new URLSearchParams(new URL(window.location.href).search);

    // reset search param
    resetSearchParam();
  }, []);


  // Row double click (상세 페이지로 이동)
  const onRowDoubleClick = (productDto: ProductDto) => {
    navigate(`/product/${productDto.id}`);
  }

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: string,
  ) => {
    confirm();
    if (!!selectedKeys && selectedKeys.length > 0) {
      searchOptions.text.set(dataIndex, selectedKeys[0]);
      setSearchOptions({...searchOptions, last: new Date()});
    }
  };
  const handleReset = (dataIndex:string, clearFilters: () => void) => {
    clearFilters();
    if(searchOptions.text.has(dataIndex)) {
      searchOptions.text.delete(dataIndex);
      setSearchOptions({...searchOptions, last: new Date()});
    }
  };

  const getColumnSearchProps = (dataIndex: string): ColumnType<ProductDto> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }:FilterDropdownProps) => {
      return (
        <div style={{padding: 8}} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            style={{marginBottom: 8, display: 'block'}}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
              icon={<SearchOutlined/>}
              size="small"
              style={{width: 90}}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(dataIndex, clearFilters)}
              size="small"
              style={{width: 90}}
            >
              Reset
            </Button>
          </Space>
        </div>
      )
    },
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    render: (text) => {
      const searchText = searchOptions.text.get(dataIndex);
      return (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[!!searchText?searchText:'']}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      )},
  });


  ////////////////////////////////////////////////////
  // Table description
  ////////////////////////////////////////////////////
  const orderColumDefine: ColumnsType<ProductDto> = [
    {
      title: t("table.title.name"),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      ...getColumnSearchProps('name'),
      render: (_, { name }) => {
        const searchText = searchOptions.text.get('name');
        return (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[!!searchText?searchText:'']}
            autoEscape
            textToHighlight={name}
          />
        )
      }
    },
    {
      title: t("table.title.manager"),
      dataIndex: 'owners.name',
      key: 'owners.name',
      ...getColumnSearchProps('owners.name'),
      render: (_, { owners }) => {
        const searchText = searchOptions.text.get('owners.name');
        return (
          !! owners
            ? <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[!!searchText?searchText:'']}
              autoEscape
              textToHighlight={owners.map(o => o.name ? o.name.toString() : '').join(", ")}
            />
            : <></>
        )
      }
    },
    {
      title: t("table.title.createdAt"),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      render: (_, {createdAt}) => {
        return (
          <>
            {!!createdAt ? dayjs(createdAt).format('YYYY-MM-DD') : ''}
          </>
        )
      }
    },
    {
      title: t("table.title.productState"),
      dataIndex: 'state',
      key: 'state',
      sorter: true,
      render: (_, {state, stateName}) => {
        const color = PRODUCT_STATE_COLOR(state);
        return (
          <Tag color={color} key={state}>
            {stateName}
          </Tag>
        )
      }
    },
    {
      title: t("table.title.attaches"),
      dataIndex: 'attaches',
      key: 'attaches',
      sorter: true,
      render: (_, {serviceFiles}) => {
        return serviceFiles ? serviceFiles.length : 0
      }
    },
    {
      title: t("table.title.lastUpdate"),
      dataIndex: 'lastUpdatedAt',
      key: 'lastUpdatedAt',
      sorter: true,
      render: (_, {updatedAt}) => {
        return (
          <>
            {!!updatedAt ? dayjs(updatedAt).format('YYYY-MM-DD HH:mm:ss') : ''}
          </>
        )
      }
    }
  ]


  return (
    <>
      <main>
        <Table
          columns={orderColumDefine}
          dataSource={[...tableDataSource]}
          rowKey='id'
          onChange={
            (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<ProductDto> | any) =>
              ON_TABLE_CHANGE(searchOptions, setSearchOptions, pagination, filters, sorter)
          }
          onRow={(record, rowIndex) => {
            return {
              onDoubleClick: (evt) => onRowDoubleClick(record),
            }
          }}
          pagination={{
            current: searchOptions.currentPage,
            total: searchOptions.totalElements,
            pageSize: ITEMS_PER_PAGE,
            position: ['bottomCenter'],
            hideOnSinglePage: false,
          }}
        />
      </main>
    </>
  )
}