import {Col, Descriptions, message, Row, Space, DatePicker, Radio, RadioChangeEvent} from "antd";
import CountUp from 'react-countup';
import {useResetRecoilState} from "recoil";
import {currentUser} from "../../state";
import {useTranslation} from "react-i18next";
import {CountResult, ORDER_STATE, OrderStat} from "../../types";
import {API} from "../../config";
import {CHECK_RESPONSE, ERROR_MESSAGE, HEADER, NUMBER} from "../../utils";
import {useEffect, useState} from "react";
import {Bar, Chart} from "react-chartjs-2";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {CHART_BORDER, CHART_COLOR, DATE_FORMAT, DATE_PICKER_TYPE, VAR} from "../../const";
import {LabelOptions} from "chartjs-plugin-datalabels/types/options";
import locale from "antd/es/date-picker/locale/ko_KR";

import dayjs from "dayjs";
import type { Dayjs } from 'dayjs';
import 'dayjs/locale/ko';

ChartJS.register(
    LinearScale,
    CategoryScale,
    PointElement,
    LineElement,
    Legend,
    BarElement,
    Title,
    Tooltip,
    ChartDataLabels
);

export const Home = () => {
  // Recoil
  const resetUserState = useResetRecoilState(currentUser);

  // i18n
  const { t } = useTranslation();

  // Formatter
  const formatter = (value: number|any) => <CountUp end={value} separator="," />;

  const datePickerOptions = [
    //{label:"년", value: "year"},
    {label: "월별", value: "month"},
    {label: "일별", value: "date"},
  ];
  interface DateRange{
    to:string,
    from:string
  }


  ///////////////////////////////////////////////////////
  const [clientOrderHistoryData, setClientOrderHistoryData] = useState<OrderStat[]>([]);
  const [partnerOrderHistoryData, setPartnerOrderHistoryData] = useState<OrderStat[]>([]);
  const [nodeClientOrderCount, setNodeClientOrderCount] = useState<CountResult[]>();
  const [nodePartnerOrderCount, setNodePartnerOrderCount] = useState<CountResult[]>();

  const [ pickerType, setPickerType ] = useState<string>("date");
  const [ dateRange, setDateRange ] = useState<DateRange>({
    to: dayjs().subtract(14,'days').format(DATE_FORMAT.YYYY_MM_DD),
    from: dayjs().format(DATE_FORMAT.YYYY_MM_DD)

  });
  const { RangePicker } = DatePicker;

  ///////////////////////////////////////////////////////


  const onPickerTypeChange = ({ target: { value } }: RadioChangeEvent) => {
    switch (value){
      case DATE_PICKER_TYPE.YEAR:
          setDateRange({
            to: dayjs().subtract(2,'years').format(DATE_FORMAT.YYYY),
            from: dayjs().format(DATE_FORMAT.YYYY)
          })
        break;
      case DATE_PICKER_TYPE.MONTH:
        setDateRange({
          to: dayjs().subtract(5,'months').format(DATE_FORMAT.YYYY_MM),
          from: dayjs().format(DATE_FORMAT.YYYY_MM)
        })
        break;
      case DATE_PICKER_TYPE.DATE:
        setDateRange({
          to: dayjs().subtract(14,'days').format(DATE_FORMAT.YYYY_MM_DD),
          from: dayjs().format(DATE_FORMAT.YYYY_MM_DD)

        })
        break;
    }
    setPickerType(value);

  };
  const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
    if (dates) {
      setDateRange({
        to:dateStrings[0],
        from:dateStrings[1]
      })
    }
  };
  const ComponentRangePicker = () =>{
    switch (pickerType){
      case DATE_PICKER_TYPE.YEAR:
        return <RangePicker locale={locale}
                            defaultValue={[
                                            dayjs(dateRange.to, DATE_FORMAT.YYYY),
                                            dayjs(dateRange.from, DATE_FORMAT.YYYY)
                                        ]}
                            onChange={onRangeChange}
                            picker={DATE_PICKER_TYPE.YEAR}/>;
      case DATE_PICKER_TYPE.MONTH:
        return <RangePicker locale={locale}
                            defaultValue={[
                              dayjs(dateRange.to, DATE_FORMAT.YYYY_MM),
                              dayjs(dateRange.from, DATE_FORMAT.YYYY_MM)
                            ]}
                            onChange={onRangeChange}
                            picker={DATE_PICKER_TYPE.MONTH}/>;
      default:
        return <RangePicker locale={locale}
                            defaultValue={[
                              dayjs(dateRange.to, DATE_FORMAT.YYYY_MM_DD),
                              dayjs(dateRange.from, DATE_FORMAT.YYYY_MM_DD)
                            ]}
                            onChange={onRangeChange}
                            picker={DATE_PICKER_TYPE.DATE}/>;

    }
  }

  const initOrderStateStats = (): CountResult[] => {
    return Object.keys(ORDER_STATE).filter(k => isNaN(Number(k))).map((k) => ({key: k, label: t(`order.state.${k.toLowerCase()}`), children: 0} as CountResult));
  }
  const updateCountResult = (init:CountResult[], data?:CountResult[]): CountResult[] => {
    if (data) {
      for(const key in data) {
        const hit = init.find(id => {

          return id.key === key.toString();
        });
        if (hit) {
          hit.children = NUMBER(data[key]);
        }
      }
    }

    return init;
  }


  const getClientOrderStateStat = async () => {
    fetch(`${API.PRODUCT}/order/count?prop=state&filter=issuer.type:Client`, {
      method: "GET",
      headers: HEADER()
    })
        .then(res => {
          return CHECK_RESPONSE(res, resetUserState);
        })
        .then(res => {
          let orderCount = [];
          setNodeClientOrderCount(
              updateCountResult(initOrderStateStats(), res)
          )
        })
        .catch(error => {
          message.open({type: 'error', content: ERROR_MESSAGE(error, t("warning.unknown"))});
        });
  }

  const getPartnerOrderStateStat = async () => {
    fetch(`${API.PRODUCT}/order/count?prop=state&filter=issuer.type:Partner`, {
      method: "GET",
      headers: HEADER()
    })
        .then(res => {
          return CHECK_RESPONSE(res, resetUserState);
        })
        .then(res => {
          setNodePartnerOrderCount(
              updateCountResult(initOrderStateStats(), res).filter(result => result.children !== 0)
          )
        })
        .catch(error => {
          message.open({type: 'error', content: ERROR_MESSAGE(error, t("warning.unknown"))});
        });
  }

  const getDayOrderStat = async (userType:string) => {
    const now = new Date();
    fetch(`${API.PRODUCT}/order/stat?userType=${userType}&from=${dateRange.to}&to=${dateRange.from}`, {
      method: "GET",
      headers: HEADER()
    })
        .then(res => {
          return CHECK_RESPONSE(res, resetUserState);
        })
        .then(res => {
          if (userType === 'Client') setClientOrderHistoryData(res);
          else if (userType === 'Partner') setPartnerOrderHistoryData(res);
        })
        .catch(error => {
          message.open({type: 'error', content: ERROR_MESSAGE(error, t("warning.unknown"))});
        });
  }


  ///////////////////////////////////////////////////////
  const chartOrderHistoryOption = {
    responsive: true,
    maintainAspectRatio: false,
    scales:{
      y:{
        min: 0,
        ticks: {
          stepSize: 10,
        },
      }
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
      title: {
        display: true,
        //text: t('stat.title.order.history'),
      },
      datalabels: { // This code is used to display data values
        color: '#FE777B',
        anchor: 'end',
        align: 'top',
        display: (context) => context.dataset.data[context.dataIndex] !== 0
      } as LabelOptions
    },
  };

  const chartClientOrderHistoryData = {
    labels: clientOrderHistoryData.map((d:OrderStat) => d.timeValue),
    datasets: [
      {
        type: 'bar' as const,
        label: t("chart.order.order"),
        data: clientOrderHistoryData.map((d:OrderStat) => d.orderCount) as number[],
        backgroundColor: CHART_COLOR.color_01,
      },
      {
        type: 'bar' as const,
        label: t("chart.order.confirm"),
        data: clientOrderHistoryData.map((d:OrderStat) => d.confirmCount) as number[],
        backgroundColor: CHART_COLOR.BLUE,
      },
      // {
      //   type: 'bar' as const,
      //   label: t("chart.order.cancel"),
      //   data: clientOrderHistoryData.map((d:OrderStat) => d.cancelCount) as number[],
      //   backgroundColor: CHART_COLOR.color_03,
      //   borderColor: CHART_BORDER.color_03
      // },
      {
        type: 'line' as const,
        label: t("chart.order.send"),
        data: clientOrderHistoryData.map((d:OrderStat) => d.sendMailCount) as number[],
        borderColor: CHART_BORDER.MAGENTA
      },
      {
        type: 'line' as const,
        label: t("chart.order.download"),
        data: clientOrderHistoryData.map((d:OrderStat) => d.downloadCount) as number[],
        borderColor: CHART_BORDER.LAVENDER
      },
      {
        type: 'bar' as const,
        label: t("chart.order.refund"),
        data: clientOrderHistoryData.map((d:OrderStat) => d.refundCount) as number[],
        backgroundColor: CHART_COLOR.TEAL,
      }
    ]
  };

  const chartPartnerOrderHistoryData = {
    labels: partnerOrderHistoryData.map((d:OrderStat) => d.timeValue),
    datasets: [
      {
        label: t("chart.order.send"),
        data: partnerOrderHistoryData.map((d:OrderStat) => d.sendMailCount) as number[],
        backgroundColor: CHART_COLOR.MAGENTA,
        borderColor: CHART_BORDER.color_04
      },
      {
        label: t("chart.order.download"),
        data: partnerOrderHistoryData.map((d:OrderStat) => d.downloadCount) as number[],
        backgroundColor: CHART_COLOR.LAVENDER,
        borderColor: CHART_BORDER.color_05
      }
    ]
  };


  useEffect(() => {
    getClientOrderStateStat();
    getPartnerOrderStateStat();
    getDayOrderStat('Client');
    getDayOrderStat('Partner');
  }, []);


  useEffect(() =>{
    if(!!dateRange){
      getDayOrderStat('Client');
      getDayOrderStat('Partner');
    }
  }, [dateRange])
  ///////////////////////////////////////////////////////
  return (
      <>
        <Row gutter={[16,8]}>
          <Col span={12}>
            <div className={"search-box"}>
              <Radio.Group options={datePickerOptions} onChange={onPickerTypeChange} value={pickerType} />
              <ComponentRangePicker />
            </div>
          </Col>
          <Col span={12}>

          </Col>
          <Col span={12}>
            <div className={"title-box"}>
              <h2>{t('stat.title.order.history')}</h2>
            </div>
            <div className={"chart-box"}>
              <Chart type='bar' options={chartOrderHistoryOption} data={chartClientOrderHistoryData} />
            </div>
          </Col>
          <Col span={12}>
            <div className={"title-box"}>
              <h2>{t('stat.title.order.partner-history')}</h2>
            </div>
            <div className={"chart-box"}>
              <Bar options={chartOrderHistoryOption} data={chartPartnerOrderHistoryData} />
            </div>
          </Col>
          <Col span={12}>
            <div className={"title-box"}>
              <h2>{t('stat.title.order.state')}</h2>
            </div>
            <div className={"condition-box"}>
              <Descriptions layout="vertical" bordered items={nodeClientOrderCount} />
            </div>
          </Col>
          <Col span={12}>
            <div className={"title-box"}>
              <h2>{t('stat.title.order.partner-state')}</h2>
            </div>
            <div className={"condition-box"}>
              <Descriptions layout="vertical" bordered items={nodePartnerOrderCount} />
            </div>
          </Col>
        </Row>
      </>
  );
};