// Application configurations

const BASE_URL:string = '';
const API_PREFIX:string = '/api/v1';
const API_URL:string = `${BASE_URL}${API_PREFIX}`;

export const API = {
    AUTH: `${API_URL}/auth`,
    USER: `${API_URL}/user`,
    PRODUCT: `${API_URL}/product`,
    FILE: `${API_URL}/file`,
    AUDIT: `${API_URL}/audit`,
}