import { useTranslation } from "react-i18next";

export const About = () => {
    const { t } = useTranslation();

    return (
        <main>
            <h1>{t("TehMetis")}</h1>
            <p>
              지혜의 신 TheMetis(데페미스)의 '필승전략' 관리자 사이트 입니다.
            </p>
        </main>
    )
}