import React from 'react';
import {Button, Checkbox, Form, Input, message} from 'antd';
import {LockOutlined, UserOutlined} from '@ant-design/icons';

import {useTranslation} from "react-i18next";

import {API} from "../../config";
import {HEADER} from "../../utils";
import {IdLoginReq as LoginReq} from "../../types";
import {useRecoilState} from "recoil";
import {currentUser} from "../../state";
import {Navigate} from "react-router-dom";

export const SignIn: React.FC = () => {
  // recoil
  const [userState, setUserState] = useRecoilState(currentUser);

  // i18n
  const { t} = useTranslation();

  const onFinish = (values: any) => {
    console.log('Login user ID: ', values.username);

    if (userState.authed) return <Navigate to="/" />; // 인증된 경우

    fetch(`${API.AUTH}/login`, {
      method: "POST",
      headers: HEADER(),
      body: JSON.stringify({
        type: "Id",
        username: values.username,
        password: values.password
      } as LoginReq)
    })
      .then(async res => {
          if (res.ok) {
            return res.json()
          }
          const text = await res.text();
          throw new Error(text);
        }
      )
      .then(res => {
        setUserState({
          ...userState,
          type: res.type,
          userId: res.id,
          username: res.username,
          name: res.name,
          email: res.email,
          authed: (res.type === 'Admin' || res.type === 'Operator') && !!res.id
        })
      })
      .catch(error => {
        // do things with the error, like logging them:
        const emsg = !!error.message && error.message.length > 0 ? JSON.parse(error.message).message : t("warning.signin.fail");
        console.error(emsg);
        message.open({
          type: 'error',
          content: emsg
        });
      })
  };

  if (userState.authed) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: false,
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: t("message.required.id"),
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder={t("user.id")}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: t("message.required.password"),
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder={t("user.password")}
            // disable copy & paste
            onPaste={(e)=>{
              e.preventDefault()
              return false;
            }}
            onCopy={(e)=>{
              e.preventDefault()
              return false;
            }}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button">
            {t("user.login")}
          </Button>
        </Form.Item>
      </Form>
    </>
  )
};