import {ColumnsType} from "antd/es/table";
import {CheckboxValueType} from "antd/es/checkbox/Group";
import {useTranslation} from "react-i18next";
import {Badge, Checkbox, PaginationProps, Space, Table, Tag} from "antd";
import {useEffect, useState} from "react";
import {COLOR, ITEMS_PER_PAGE, MAPPING, PARAM, VAR} from "../../const";
import {UserDto} from "../../types";
import {API} from "../../config";
import {FOCUS_COLOR, NULL_OR_EMPTY_STRING, TABLE_FETCH} from "../../utils";
import {useResetRecoilState} from "recoil";
import {currentUser} from "../../state";
import dayjs from "dayjs";

export const UserClientList = () => {
  // Recoil
  const resetUserState = useResetRecoilState(currentUser);

  // i18n
  const { t } = useTranslation();

  // const searchParams = new URLSearchParams(window.location.search);
  const [searchParams, setSearchParams] = useState(new URLSearchParams());
  // OrderState default settings
  const [selectedOrderState, setSelectedOrderState] = useState<string[]>([]);

  // datasource
  const [tableDataSource, setTableDataSource] = useState([]);

  // search options
  interface searchOptionType {
    currentPage: number,
    totalElements: number,
    last: Date,
    orderState?:string
  }
  const [searchOptions, setSearchOptions] = useState<searchOptionType>(
    {
      currentPage: 1,
      totalElements: 0,
      last: new Date()
    }
  );

  // Order State Checkbox options
  const orderStateOptions = [
    {label: t('order.state.order'), value: VAR.ORDER_STATE_ORDER},
    {label: t('order.state.confirm'), value: VAR.ORDER_STATE_CONFIRM},
    {label: t('order.state.resend'), value: VAR.ORDER_STATE_RESEND},
    {label: t('order.state.close'), value: VAR.ORDER_STATE_CLOSE},
  ];

  const resetSearchParam = () => {
    const param = new URLSearchParams();
    param.append('filter', 'type~Client;Partner');
    param.append('mapping', MAPPING.normal);
    param.append('page', (searchOptions.currentPage-1).toString());
    param.append('size', ITEMS_PER_PAGE.toString());
    param.append('sort', 'lastLoginAt,desc');

    if (!NULL_OR_EMPTY_STRING(searchOptions.orderState)) {
      param.append('filter', `orders.state~${searchOptions.orderState}`)
    }

    setSearchParams(param);
  }

  useEffect(() => {
    resetSearchParam();
  }, [searchOptions.last]);

  useEffect(() => {
    if (!searchParams || searchParams.size === 0) return; // searchParam이 초기화 되지 않았을 때
    TABLE_FETCH(
      `${API.USER}?${searchParams.toString()}`,
      setTableDataSource, searchOptions, setSearchOptions,
      resetUserState, t("warning.signin.fail")
    );
  }, [searchParams]);

  // First Loading (URL parameter fetch)
  useEffect(() => {
    // query parameter Setting
    const param = new URLSearchParams(new URL(window.location.href).search);

    // Order State checkbox
    const orderStateParams = param.getAll(PARAM.ORDER_STATE);
    setSelectedOrderState(orderStateParams);

    // reset search param
    resetSearchParam();
  }, []);


  const onPageChange: PaginationProps['onChange'] = (pageNumber) => {
    console.log('Page: ', pageNumber);
    if (pageNumber !== searchOptions.currentPage) {
      setSearchOptions({...searchOptions, currentPage: pageNumber, last: new Date()});
    }
  };

  const onOrderStateChange = (checkedValues: CheckboxValueType[]) => {
    const checkedStr = checkedValues.join(';');
    const checkedStrArr = checkedValues.map(v => v.toString());
    if (NULL_OR_EMPTY_STRING(checkedStr)) {
      setSearchOptions({...searchOptions, currentPage: 1, orderState: undefined, last: new Date()});
      setSelectedOrderState([]);
    } else {
      setSearchOptions({...searchOptions, currentPage: 1, orderState: checkedStr, last: new Date()});
      setSelectedOrderState(checkedStrArr);
    }
  }

  ////////////////////////////////////////////////////
  // Table description
  ////////////////////////////////////////////////////
  const clientUserColumDefine: ColumnsType<UserDto> = [
    {
      title: t("table.title.type"),
      dataIndex: 'type',
      key: 'type',
      render: (_, { type }) => {
        let color =  type === 'Client' ? 'green' : 'black';
        return (
          <Tag color={color} key={type}>
            {type.toUpperCase()}
          </Tag>
        )
      }
    },
    {
      title: t("table.title.name"),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t("table.title.email"),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t("table.title.key"),
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: t("table.title.workplace"),
      dataIndex: 'workPlace',
      key: 'workPlace',
    },
    {
      title: t("table.title.orderState"),
      dataIndex: 'orderStat',
      key: 'orderStat',
      render: (_, { orderStat }) => {
        if (!orderStat) return (<></>);
        return (
          <Space>
            <Badge count={orderStat.orderCount} showZero text={t('order.state.order')}
                   color={FOCUS_COLOR(orderStat.orderCount, COLOR.red_03)} />
            <Badge count={orderStat.confirmCount} showZero text={t('order.state.confirm')}
                   color={FOCUS_COLOR(orderStat.confirmCount, COLOR.green_02)} />
            <Badge count={orderStat.resendReqCount} showZero text={t('order.state.resend')}
                   color={FOCUS_COLOR(orderStat.resendReqCount, COLOR.purple_02)} />
            <Badge count={orderStat.downloadCount + orderStat.closeCount} showZero text={t('order.state.close')}
                   color={FOCUS_COLOR(orderStat.downloadCount + orderStat.closeCount, COLOR.blue_02)} />
          </Space>
        )
      }
    },
    {
      title: t("table.title.lastLogin"),
      dataIndex: 'lastLoginAt',
      key: 'lastLoginAt',
      render: (_, {lastLoginAt}) => {
        return (
          <>
            {!!lastLoginAt ? dayjs(lastLoginAt).format('YYYY-MM-DD HH:mm:ss') : ''}
          </>
        )
      }
    }
  ]

  return (
    <>
      <main>
        <Space>
          <Checkbox.Group
            options={orderStateOptions}
            defaultValue={[]}
            value={selectedOrderState}
            onChange={onOrderStateChange} />
        </Space>
        <Table
          columns={clientUserColumDefine}
          dataSource={[...tableDataSource]}
          rowKey='id'
          pagination={{
            current: searchOptions.currentPage,
            total: searchOptions.totalElements,
            pageSize: ITEMS_PER_PAGE,
            position: ['bottomCenter'],
            hideOnSinglePage: false,
            onChange: onPageChange
          }}
        />
      </main>
    </>
  )
}