import {useRecoilState, useResetRecoilState} from "recoil";

import {Menu, MenuProps} from "antd";
import {useNavigate} from "react-router-dom"
import {
  FundOutlined,
  HomeOutlined,
  InfoOutlined,
  LoginOutlined,
  LogoutOutlined, UserOutlined
} from "@ant-design/icons";

import {currentUser} from "../state";
import {useTranslation} from "react-i18next";
import {API} from "../config";
import {HEADER} from "../utils";

const menuTargets = [
  { key: "home", target: `/`},
  { key: "about", target: `/about`},

  { key: "user:admin:list", target: '/user/admin/list'},
  { key: "user:client:list", target: '/user/client/list'},

  { key: "order:list:client", target: '/order/list/Client'},
  { key: "order:list:partner", target: '/order/list/Partner'},
  { key: "product:list", target: '/product/list'},

  { key: "login", target: `/login`},
  { key: "logout"},
];

export const MainMenu = () => {
  // recoil
  const [userState, setUserState] = useRecoilState(currentUser);
  const resetUserState = useResetRecoilState(currentUser);

  // i18n
  const {t} = useTranslation();

  // navigate
  const navigate = useNavigate();

  // menus
  const items: MenuProps['items'] = [
    {
      label: t("menu.home"),
      key: 'home',
      icon: <HomeOutlined />,
    },
    {
      label: t("menu.product"),
      key: 'product',
      icon: <FundOutlined />,
      children: [
        {
          label: t("menu.order_list"),
          key: 'order:list:client',
        },
        {
          label: t("menu.free_list"),
          key: 'order:list:partner',
        },
        {
          label: t("menu.product_list"),
          key: 'product:list',
        },
      ],
    },
    {
      label: t("menu.user"),
      key: 'user',
      icon: <UserOutlined />,
      children: [
        {
          label: t("menu.user_client_list"),
          key: 'user:client:list',
        },
        {
          label: t("menu.user_admin_list"),
          key: 'user:admin:list',
        },
      ],
    },
    {
      label: t("menu.about"),
      key: 'about',
      icon: <InfoOutlined />,
    },
    {
      label: t("user.login"),
      key: 'login',
      icon: <LoginOutlined />,
      disabled: !!userState.userId,
    },
    {
      label: t("user.logout"),
      key: 'logout',
      icon: <LogoutOutlined />,
      disabled: !userState.userId,
    },
  ];

  const onClick: MenuProps['onClick'] = (e) => {
    console.log('click ', e);
    setUserState({...userState, menu: e.key})
    const { target } = menuTargets.find(item => item.key === e.key) || {};
    if (e.key === 'logout') {
      fetch(`${API.AUTH}/logout`, {
        method: "POST",
        headers: HEADER()
      }).then(res => {
        if (res.ok) {
          resetUserState();
        }
      })
    } else if (target) {
      navigate(target);
    }
  };

  return <Menu onClick={onClick} selectedKeys={[userState.menu]} mode="horizontal" items={items} />;
};